img,
video {
    cursor: move;
    user-select: none;
}

img:active,
video:active {
    cursor: grabbing;
}
